import Start from "./pages/start";

function App() {
  return (
    <Start/>
  );
}

export default App;

